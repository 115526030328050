@import '~@core/scss/core.scss';
@import './assets/scss/styles';
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~@core/scss/angular/libs/select.component.scss';
@import '~ngx-ui-switch/ui-switch.component.scss';

.module-header {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflow-x-unset {
  overflow-x: unset;
}

.p-0 {
  padding: 0 !important;
}

.height-300px {
  height: 300px;
}

.height-30-px {
  height: 30px;
}

.p-100px {
  padding: 100px;
}

.max-width-300-px {
  max-width: 300px;
}

.max-height-500px {
  max-height: 500px;
}

.max-height-700px {
  max-height: 700px;
}

.cursor-pointer {
  cursor: pointer;
}

.module-header {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.color-white {
  color: white !important;
}

.mt-18px {
  margin-top: 18px !important;
}

.mt-5px {
  margin-top: 5px;
}

.mt-9px {
  margin-top: 9px;
}

.mt-17px {
  margin-top: 17px;
}

.mt-40px {
  margin-top: 40px;
}

.mr-8px {
  margin-right: 8px;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-10px {
  margin-top: 10px;
}

.mt--15px {
  margin-top: -15px !important;
}

.mt--10px {
  margin-top: -8px;
}

.ml-6px {
  margin-left: 6px;
}

.mt-12px {
  margin-top: 12px;
}

.mt--5px {
  margin-top: -5px;
}

.ml--8px {
  margin-left: -8px;
}

.pb-18px {
  padding-bottom: 18px;
}

.background-white {
  background-color: white;
}

.btn-close-modal {
  outline: none !important;
}

.make-underline:hover {
  text-decoration: underline;
}

.card-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resize-none {
  resize: none;
}

.search-card {
  background-color: white;
  box-shadow: 0 2px 24px 0 #c7d9ff;
  padding-bottom: 15px;
  border: 2px solid #e2e4ea;
  margin: 15px 0 -22px;
}

.mb--5px {
  margin-bottom: -5px;
}

.mb--8px {
  margin-bottom: -8px;
}

.mb--10px {
  margin-bottom: -10px;
}

.mb--15px {
  margin-bottom: -15px;
}

.height-145-px {
  height: 145px;
}

.border-3px-dotted {
  border: 3px dotted #000000;
}

.display-hidden {
  display: none !important;
}

.shadow-none {
  box-shadow: none !important;
}

.pagination-table ngb-pagination {
  margin: 0 auto !important;
}

.shadow-right-light {
  box-shadow: 1px 0 0 0 #e0e0e0 !important;
}

.shadow-bottom-light {
  box-shadow: 0 1px 0 0 #e0e0e0 !important;
}

.shadow-complete {
  box-shadow: 0 0 1px 1px #e0e0e0 !important;
}

.border-radius-4px {
  border-radius: 4px 4px 4px 4px;
}

.text-inherit {
  text-align: inherit !important;
}

.f_17 {
  font-size: 17px;
}

.f_20 {
  font-size: 20px;
}

.f_23 {
  font-size: 23px;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.overflow-table::-webkit-scrollbar-corner {
  display: none; /* Chrome Hide Scroll bar */
}

/*.overflow-table {
  -ms-overflow-style: none; !* IE and Edge *!
  scrollbar-width: none; !* Firefox *!
}*/

.overflow-table .table-list {
  margin-left: -15px;
  width: 103%;
}

.overflow-table::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 10px 10px 10px 10px;
}

.overflow-table::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 10px 10px 10px 10px;
}

.width-85-per {
  width: 85% !important;
}

.width-120-per {
  width: 120% !important;
}

.width-103-per {
  width: 103% !important;
}

.text-italic {
  font-style: italic !important;
}

.ml-5px {
  margin-left: 5px;
}

.width-100-per {
  width: 100%;
}

.bottom-shadow-light {
  box-shadow: 0 1px 0 0 #e0e0e0;
}

.upper-shadow-light {
  box-shadow: 0 -1px 0 0 #e0e0e0;
}

.display-block {
  display: block !important;
}

.display-hidden {
  display: none !important;
}

.mr-5px {
  margin-right: 5px;
}

.ml--5px {
  margin-left: -5px;
}

.mr-10px {
  margin-right: 10px;
}

.ml--10px {
  margin-left: -10px;
}

.profile-background-image {
  background: url("./assets/images/image-backgrounds/200-200.png") 100%;
}

.color-facebook {
  color: #4267B2 !important;
}

.color-twitter {
  color: #1DA1F2 !important;
}

.color-instagram {
  color: #8a3ab9 !important;
}

.background-success {
  background-color: #90EE90 !important;
}

.background-warning {
  background-color: #FFFF99 !important;
}

.background-danger {
  background-color: #FFCCCB !important;
}
